<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
    class="pa-0"
  >
    <validation-observer>
      <v-card>
        <v-card-title primary-title />
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-row
                v-for="(value, index) in fields1"
                :key="index"
                class="align"
              >
                <v-col
                  v-if="value.type !== 'spacer'"
                  class="text-button align-self-center pt-0"
                  cols="4"
                >
                  <div>
                    <span class="font-weight-bold">
                      {{
                        $t('messages.' + value.key)
                      }}
                    </span>
                    <span class="red--text">
                      {{
                        value.validate
                          ? value.validate.includes('required')
                            ? '*'
                            : ''
                          : ''
                      }}
                    </span>
                  </div>
                </v-col>
                <v-col
                  class="text-button align-self-center pb-0 pt-0"
                  cols="7"
                >
                  <validation-provider
                    v-if="value.type === 'date-text'"
                    v-slot="{ errors }"
                    :name="value.key"
                    :rules="value.validate ? value.validate : ''"
                  >
                    <div v-if="'menu' in value">
                      <v-row>
                        <v-col cols="8">
                          <v-text-field
                            v-model="value.value"
                            :name="value.key"
                            outlined
                            autocomplete="off"
                            :error-messages="errors[0] ? errors[0] : value.customerValidate"
                            :placeholder="$t(value.placeholder)"
                            @keydown="checkDate"
                          >
                            <template v-slot:append />
                          </v-text-field>
                        </v-col>
                        <v-col cols="1">
                          <v-menu
                            :ref="'menu' + value.key"
                            v-model="value.menu"
                            :close-on-content-click="false"
                            :return-value.sync="value.value"
                            transition="scale-transition"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="mt-4 mr-3"
                                v-bind="attrs"
                                left
                                v-on="on"
                              >
                                mdi-calendar
                              </v-icon>
                            </template>
                            <v-date-picker
                              v-model="value.value"
                              no-title
                              scrollable
                              :max="value.key === 'dob' ? value.max_date : ''"
                            >
                              <v-spacer />
                              <v-btn
                                text
                                color="primary"
                                @click="value.menu = false"
                              >
                                {{ $t('messages.cancel') }}
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="saveValueDate(value)"
                              >
                                {{ $t('messages.ok') }}
                              </v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :ref="'date' + value.key"
                            disabled
                            outlined
                            :value="value.value.length > 0 ? value.key === 'dob' ? Math.round(new Date().getFullYear() - new Date(value.value).getFullYear()) : expiryDate(value.value) : '0'"
                          />
                        </v-col>
                      </v-row>
                    </div>
                    <div v-else>
                      <v-text-field
                        v-model="value.value"
                        :name="value.key"
                        outlined
                        :error-messages="errors[0] ? errors[0] : value.customerValidate"
                        :placeholder="$t(value.placeholder)"
                        @keydown="checkDate"
                      />
                    </div>
                  </validation-provider>
                  <validation-provider
                    v-else-if="value.type === 'select'"
                    v-slot="{ errors }"
                    :name="value.key"
                    :rules="value.validate ? value.validate : ''"
                  >
                    <v-select
                      v-model="value.value"
                      :name="value.key"
                      outlined
                      :error-messages="errors[0] ? errors[0] : value.customerValidate"
                      :items="value.items"
                      item-text="text"
                      item-value="value"
                      :placeholder="$t(value.placeholder)"
                    />
                  </validation-provider>
                  <validation-provider
                    v-else-if="value.type === 'text'"
                    v-slot="{ errors }"
                    :name="value.key"
                    :rules="value.validate ? value.validate : ''"
                  >
                    <v-text-field
                      v-model="value.value"
                      :disabled="value.disable"
                      :placeholder="$t(value.placeholder)"
                      :name="value.key"
                      outlined
                      :error-messages="errors[0] ? errors[0] : value.customerValidate"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <div class="mt-16">
                <v-row
                  v-for="(value, index) in fields3"
                  :key="index"
                  class="align"
                >
                  <v-col
                    v-if="value.type !== 'spacer'"
                    class="text-button align-self-center pt-0"
                    cols="4"
                  >
                    <div>
                      <span class="font-weight-bold">
                        {{
                          $t('messages.' + value.key)
                        }}
                      </span>
                      <span class="red--text">
                        {{
                          value.validate
                            ? value.validate.includes('required')
                              ? '*'
                              : ''
                            : ''
                        }}
                      </span>
                    </div>
                  </v-col>
                  <v-col
                    class="text-button align-self-center pb-0 pt-0"
                    cols="7"
                  >
                    <validation-provider
                      v-if="value.type === 'date-text'"
                      v-slot="{ errors }"
                      :name="value.key"
                      :rules="value.validate ? value.validate : ''"
                    >
                      <div v-if="'menu' in value">
                        <v-row>
                          <v-col cols="8">
                            <v-text-field
                              v-model="value.value"
                              :name="value.key"
                              outlined
                              autocomplete="off"
                              :error-messages="errors[0] ? errors[0] : value.customerValidate"
                              :placeholder="$t(value.placeholder)"
                              @keydown="checkDate"
                            >
                              <template v-slot:append />
                            </v-text-field>
                          </v-col>
                          <v-col cols="1">
                            <v-menu
                              :ref="'menu' + value.key"
                              v-model="value.menu"
                              :close-on-content-click="false"
                              :return-value.sync="value.value"
                              transition="scale-transition"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  class="mt-4 mr-3"
                                  v-bind="attrs"
                                  left
                                  v-on="on"
                                >
                                  mdi-calendar
                                </v-icon>
                              </template>
                              <v-date-picker
                                v-model="value.value"
                                locale="ja-jn"
                                no-title
                                scrollable
                                :max="value.key === 'dob' ? value.max_date : ''"
                              >
                                <v-spacer />
                                <v-btn
                                  text
                                  color="primary"
                                  @click="value.menu = false"
                                >
                                  {{ $t('messages.cancel') }}
                                </v-btn>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="saveValueDate(value)"
                                >
                                  {{ $t('messages.ok') }}
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="3">
                            <v-text-field
                              :ref="'date' + value.key"
                              disabled
                              outlined
                              :value="value.value.length > 0 ? value.key === 'dob' ? Math.round(new Date().getFullYear() - new Date(value.value).getFullYear()) : expiryDate(value.value) : '0'"
                            />
                          </v-col>
                        </v-row>
                      </div>
                      <div v-else>
                        <v-text-field
                          v-model="value.value"
                          :name="value.key"
                          outlined
                          :error-messages="errors[0] ? errors[0] : value.customerValidate"
                          :placeholder="$t(value.placeholder)"
                          @keydown="checkDate"
                        />
                      </div>
                    </validation-provider>
                    <validation-provider
                      v-else-if="value.type === 'select'"
                      v-slot="{ errors }"
                      :name="value.key"
                      :rules="value.validate ? value.validate : ''"
                    >
                      <v-select
                        v-model="value.value"
                        :name="value.key"
                        outlined
                        :error-messages="errors[0] ? errors[0] : value.customerValidate"
                        :items="value.items"
                        item-text="text"
                        item-value="value"
                        :placeholder="$t(value.placeholder)"
                        @change="getVisaDocs"
                      />
                    </validation-provider>
                    <validation-provider
                      v-else-if="value.type === 'text'"
                      v-slot="{ errors }"
                      :name="value.key"
                      :rules="value.validate ? value.validate : ''"
                    >
                      <v-text-field
                        v-model="value.value"
                        :placeholder="$t(value.placeholder)"
                        :name="value.key"
                        outlined
                        :error-messages="errors[0] ? errors[0] : value.customerValidate"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col cols="6">
              <v-row
                v-for="(value, index) in fields2"
                :key="index"
                class="align"
              >
                <v-col
                  v-if="value.type !== 'spacer'"
                  class="text-button align-self-center pt-0"
                  cols="4"
                >
                  <div>
                    <span class="font-weight-bold">
                      {{
                        $t('messages.' + value.key)
                      }}
                    </span>
                    <span class="red--text">
                      {{
                        value.validate
                          ? value.validate.includes('required')
                            ? '*'
                            : ''
                          : ''
                      }}
                    </span>
                  </div>
                </v-col>
                <v-col
                  class="text-button align-self-center pb-0 pt-0"
                  cols="7"
                >
                  <validation-provider
                    v-if="value.type === 'date-text'"
                    v-slot="{ errors }"
                    :name="value.key"
                    :rules="value.validate ? value.validate : ''"
                  >
                    <div v-if="'menu' in value">
                      <v-row>
                        <v-col cols="8">
                          <v-text-field
                            v-model="value.value"
                            :name="value.key"
                            outlined
                            autocomplete="off"
                            :error-messages="errors[0]"
                            :placeholder="$t(value.placeholder)"
                            @keydown="checkDate"
                          >
                            <template v-slot:append />
                          </v-text-field>
                        </v-col>
                        <v-col cols="1">
                          <v-menu
                            :ref="'menu' + value.key"
                            v-model="value.menu"
                            :close-on-content-click="false"
                            :return-value.sync="value.value"
                            transition="scale-transition"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="mt-4 mr-3"
                                v-bind="attrs"
                                left
                                v-on="on"
                              >
                                mdi-calendar
                              </v-icon>
                            </template>
                            <v-date-picker
                              v-model="value.value"
                              locale="ja-jn"
                              no-title
                              scrollable
                              :max="value.key === 'dob' ? value.max_date : ''"
                            >
                              <v-spacer />
                              <v-btn
                                text
                                color="primary"
                                @click="value.menu = false"
                              >
                                {{ $t('messages.cancel') }}
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="saveValueDate(value)"
                              >
                                {{ $t('messages.ok') }}
                              </v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :ref="'date' + value.key"
                            disabled
                            outlined
                            :value="value.value.length > 0 ? value.key === 'dob' ? Math.round(new Date().getFullYear() - new Date(value.value).getFullYear()) : expiryDate(value.value) : '0'"
                          />
                        </v-col>
                      </v-row>
                    </div>
                    <div v-else>
                      <v-text-field
                        v-model="value.value"
                        :name="value.key"
                        outlined
                        :error-messages="errors[0]"
                        :placeholder="$t(value.placeholder)"
                        @keydown="checkDate"
                      />
                    </div>
                  </validation-provider>
                  <validation-provider
                    v-else-if="value.type === 'select'"
                    v-slot="{ errors }"
                    :name="value.key"
                    :rules="value.validate ? value.validate : ''"
                  >
                    <v-select
                      v-model="value.value"
                      :name="value.key"
                      outlined
                      :error-messages="errors[0] ? errors[0] : checkCollegeId"
                      :items="value.items"
                      item-text="text"
                      item-value="value"
                      :placeholder="$t(value.placeholder)"
                    />
                  </validation-provider>
                  <validation-provider
                    v-else-if="value.type === 'text'"
                    v-slot="{ errors }"
                    :name="value.key"
                    :rules="value.validate ? value.validate : ''"
                  >
                    <v-text-field
                      v-model="value.value"
                      :disabled="value.disable"
                      :placeholder="$t(value.placeholder)"
                      :name="value.key"
                      outlined
                      :error-messages="errors[0]"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <div
                class="mt-16 pa-3"
                style="border: 1px solid; border-radius: 10px;"
              >
                <v-row class="d-flex">
                  <v-col>
                    <span class="title-bank">{{ $t('messages.accountInformation') }}</span>
                  </v-col>
                </v-row>
                <v-row
                  v-for="(value, index) in fields4"
                  :key="index"
                  class="align"
                >
                  <v-col
                    v-if="value.type !== 'spacer'"
                    class="text-button align-self-center pt-0"
                    cols="4"
                  >
                    <div>
                      <span class="font-weight-bold">
                        {{
                          $t('messages.' + value.key)
                        }}
                      </span>
                    </div>
                  </v-col>
                  <v-col
                    class="text-button align-self-center pb-0 pt-0"
                    cols="7"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="value.key"
                      :rules="value.validate ? value.validate : ''"
                    >
                      <v-text-field
                        v-model="value.value"
                        :placeholder="$t(value.placeholder)"
                        :name="value.key"
                        outlined
                        :error-messages="errors[0]"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions />
      </v-card>
      <v-card class="mt-10">
        <v-card-text>
          <v-row>
            <v-col
              class="text-button align-self-center pt-0"
              cols="1"
            >
              <div>
                <span class="font-weight-bold">
                  {{
                    $t('messages.jobName')
                  }}
                </span>
              </div>
            </v-col>
            <v-col cols="3">
              <v-select
                :items="items"
                solo
              />
            </v-col>
          </v-row>
          <v-row style="border-radius:10px; border:1px solid #25ACD8">
            <v-col
              class="text-button align-self-center pt-0"
              cols="1"
            >
              <div>
                <span class="font-weight-bold">
                  {{
                    $t('messages.workDay')
                  }}
                </span>
              </div>
            </v-col>
            <v-col cols="5">
              <v-btn-toggle
                v-model="selectedDay"
                multiple
              >
                <v-btn
                  v-for="(item, index) in itemsDay"
                  :key="index"
                  class="mr-1"
                  color="#3699FF"
                >
                  {{ item.text }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <v-col
              class="text-button align-self-center pt-0"
              cols="1"
            >
              <div>
                <span class="font-weight-bold">
                  {{
                    $t('messages.shift')
                  }}
                </span>
              </div>
            </v-col>
            <v-col cols="3">
              <v-select
                v-model="selectedShift"
                :items="itemsShift"
                solo
              />
            </v-col>
            <v-col
              cols="2"
              class="text-button pt-4"
            >
              <v-btn @click="setWorkDay">
                {{ $t('button.set') }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              v-for="(item, index) in selectedDayShift"
              :key="index"
              cols="4"
              class="mr-16 ml-16 pr-10 d-flex flex-row"
            >
              <span
                class="pr-4 mb-5 d-flex align-center"
                style="width: 30%;border-radius: 10px;border: 1px solid;padding-left: 16px;margin-right: 10px;background: #F3F6F9; margin-bottom: 30px !important; color: black"
              >{{ item.dayText + '曜日' }}</span>
              <v-select
                v-model="item.shift"
                item-text="text"
                item-value="value"
                :items="itemsShift"
                solo
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!--button-->
      <v-row class="d-flex mt-3">
        <v-col
          cols="2"
          :class="1 && 'mr-auto'"
        >
          <v-btn
            large
            block
            color="info"
            @click="dialogOcr = true"
          >
            <span class="text-h5 pr-3 pl-3">{{ $t('button.ocr') }}</span>
          </v-btn>
        </v-col>
        <v-col
          cols="2"
          class="d-flex justify-space-between"
        >
          <v-btn
            large
            block
            color="info"
            type="submit"
            @click="onSubmit"
          >
            <span class="text-h5 pr-3 pl-3">{{ $t('messages.save') }}</span>
          </v-btn>
        </v-col>
        <v-col
          cols="2"
          class="d-flex justify-space-between"
        >
          <v-btn
            large
            block
            color="info"
            type="submit"
            @click="$router.push('/parttimer-list/')"
          >
            <span class="text-h5 pr-3 pl-3">{{ $t('button.cancel') }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </validation-observer>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('messages.close') }}
        </v-btn>
      </template>
    </v-snackbar>
    <!-- ocr dialog -->
    <v-dialog
      v-model="dialogOcr"
      width="500"
      persistent
    >
      <qrcode />
    </v-dialog>
  </v-container>
</template>

<script>
  import { get } from 'vuex-pathify'
  import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
  import { required, email, min } from 'vee-validate/dist/rules'
  import moment from 'moment'
  import Qrcode from '../Parttimer/Qrcode'

  export default {
    name: 'ParttimerAdd',
    components: {
      ValidationProvider,
      ValidationObserver,
      Qrcode,
    },
    data () {
      return {
        age: '',
        residenceCardAvailable: '',
        campusCardAvailable: '',
        snackbarMessage: '',
        snackbar: false,
        snackbarCloseInerval: null,
        fields1: [
          {
            key: 'name',
            value: '',
            type: 'text',
            validate: 'required',
            customerValidate: '',
          },
          {
            key: 'furiganaName',
            value: '',
            type: 'text',
            validate: 'required',
            customerValidate: '',
          },
          {
            key: 'dob',
            value: '',
            type: 'date-text',
            max_date: moment().format('YYYY-MM-DD'),
            validate: 'required|dateDob|rangeDate',
            placeholder: 'placeholder.dob',
            menu: false,
            customerValidate: '',
          },
          {
            key: 'sex',
            items: [
              { value: 1, text: '男' },
              { value: 0, text: '女' },
            ],
            value: 1,
            type: 'select',
            validate: 'required',
            customerValidate: '',
          },
        ],
        fields2: [
          {
            key: 'contactPhone',
            value: '',
            type: 'text',
            customerValidate: '',
            validate: 'phone',
          },
          {
            key: 'contactAddress',
            value: '',
            type: 'text',
            customerValidate: '',
          },
          {
            key: 'collegeId',
            items: [],
            value: '',
            validate: 'required',
            type: 'select',
          },
          {
            key: 'campusCardValidDate',
            value: '',
            type: 'date-text',
            max_date: moment().format('YYYY-MM-DD'),
            validate: 'required|dateResidenceCard',
            placeholder: 'placeholder.dob',
            menu: false,
          },
        ],
        fields3: [
          {
            key: 'language',
            value: '',
            type: 'text',
            validate: 'required',
            customerValidate: '',
          },
          {
            key: 'visaId',
            items: [],
            value: '',
            type: 'select',
            validate: 'required',
            customerValidate: '',
          },
          {
            key: 'residenceCardId',
            value: '',
            type: 'text',
            validate: 'required',
            customerValidate: '',
          },
          {
            key: 'residenceCardValidDate',
            value: '',
            type: 'date-text',
            max_date: moment().format('YYYY-MM-DD'),
            validate: 'required|dateResidenceCard',
            placeholder: 'placeholder.dob',
            menu: false,
            customerValidate: '',
          },
        ],
        fields4: [
          {
            key: 'bank',
            value: '',
            type: 'text',
          },
          {
            key: 'bankBranch',
            value: '',
            type: 'text',
          },
          {
            key: 'cardNumber',
            value: '',
            type: 'text',
          },
          {
            key: 'accountHolder',
            value: '',
            type: 'text',
          },
        ],
        dialogOcr: false,
        processCount: 0,
        checkUserName: '',
        checkPassword: '',
        checkCollegeId: '',
        selectedDay: [],
        itemsDay: [
          { value: 0, text: '日' },
          { value: 1, text: '月' },
          { value: 2, text: '火' },
          { value: 3, text: '水' },
          { value: 4, text: '木' },
          { value: 5, text: '金' },
          { value: 6, text: '土' },
        ],
        itemsShift: [],
        selectedShift: '',
        selectedDayShift: [],
      }
    },
    computed: {
      ...get('parttimer', [
        'message',
        'status',
        'error',
        'colleges',
        'dataOcrSave',
        'newParttimeEmployee',
      ]),
      ...get('visa', ['listVisa', 'visaInfo']),
    },
    watch: {
      error (value) {
        if (value) {
          this.snackbarMessage = this.$t(value)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
      message (value) {
        if (value === 'success') {
        // this.$router.push({ path: '/parttimer-list', query: { success: true } })
        }
      },
      colleges (value) {
        const field = this.fields2.find(o => o.key === 'collegeId')
        value.forEach(item => {
          field.items.push({
            value: item.id,
            text: item.name,
          })
        })
      },
      listVisa (value) {
        const field = this.fields3.find(o => o.key === 'visaId')
        value.map(item => {
          field.items.push({
            value: item.id,
            text: item.type,
          })
        })
      },
      visaInfo (value) {
        this.docsList = value.documents
        const totalDocs = this.docsList.length
        const docsCheckList = []
        this.docsList.map(item => {
          delete item.createdAt
          delete item.updatedAt
          if (item.isFulfiled === true) {
            docsCheckList.push(item)
          }
        })
        const totalChecked = docsCheckList.length
        this.lengthDocs = totalChecked + '/' + totalDocs
        this.processCount = Math.round((totalChecked / totalDocs) * 100)
      },
      dataOcrSave (value) {
        for (let [key, val] of Object.entries(value)) {
          const field =
            this.fields1.find(o => o.key === key) ||
            this.fields2.find(o => o.key === key) ||
            this.fields3.find(o => o.key === key) ||
            this.fields4.find(o => o.key === key)
          key === 'dob' ||
            key === 'campusCardValidDate' ||
            key === 'residenceCardValidDate'
            ? (val = moment(val).format('YYYY-MM-DD'))
            : key === 'sex'
              ? (val = val && val === '女' ? 0 : 1)
              : (field.value = val)
        }
        this.dialogOcr = false
      },
      newParttimeEmployee (value) {
        this.$store.dispatch(
          'parttimer/getNewPass',
          value.data.parttimeEmployee.user.password,
        )
        this.$router.push({
          path: '/parttimer-detail/' + value.data.parttimeEmployee.id,
        })
      },
    },
    created () {
      this.$store.dispatch('visa/getVisa')
    },
    mounted () {
      this.$store.dispatch('parttimer/getCollege')
      this.itemsShift = [
        { value: 0, text: '17:00 ~ 20:00' },
        { value: 1, text: '20:00 ~ 23:00' },
      ]
      this.items = []
      if (this.dialogOcr === true) {
        setTimeout(function () { this.dialogOcr = false }.bind(this), 300000)
      }
      // Define the rule globally
      extend('required', {
        ...required,
        message: this.$t('validator.required'),
      })

      extend('email', {
        ...email,
        message: this.$t('validator.email_invalid'),
      })

      extend('number', {
        validate (value) {
          return /^\+?(0|[1-9]\d*)$/.test(value)
        },
        message: this.$t('validator.must_be_number'),
      })
      extend('min', {
        ...min,
        message: this.$t('validator.password_invalid'),
      })
      extend('dateDob', {
        validate (value) {
          return /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/.test(value)
        },
        message: this.$t('生年月日は正確な形式で入力してください。'),
      })
      extend('dateResidenceCard', {
        validate (value) {
          return /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/.test(value)
        },
        message: this.$t('存在しない年月日が入力されています。'),
      })
      extend('password', {
        params: ['target'],
        validate (value, target) {
          if (typeof target === 'object') return value === target[0]
          else if (typeof target === 'string' || typeof target === 'number') {
            return value === target
          }
        },
        message: this.$t('validator.confirm_password_invalid'),
      })
      extend('phone', {
        validate (value) {
          return /^[+]?[(]?[0-9]{4}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,5}$/.test(
            value,
          )
        },
        message: this.$t('半角数字で入力してください。'),
      })
      extend('rangeDate', {
        validate (value) {
          if (moment(value).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD')) { return false }
          return true
        },
        message: this.$t('過去の日付を入力してください。'),
      })
    },
    methods: {
      saveValueDate (value) {
        this.$refs['menu' + value.key][0].save(value.value)
      },
      expiryDate (dateString) {
        var expiration = moment(dateString).format('YYYY-MM-DD')
        var currentDate = moment().format('YYYY-MM-DD')
        var days = moment(expiration).diff(currentDate, 'days')
        return days
      },
      onSubmit () {
        if (this.checkValidate()) {
          return
        }
        let payload = {}
        this.age = this.$refs.datedob[0].value
        this.residenceCardAvailable = this.$refs.dateresidenceCardValidDate[0].value
        this.campusCardAvailable = this.$refs.datecampusCardValidDate[0].value
        payload = {
          name: this.fields1.find(o => o.key === 'name').value,
          furiganaName: this.fields1.find(o => o.key === 'furiganaName').value,
          dob: this.fields1.find(o => o.key === 'dob').value,
          sex: this.fields1.find(o => o.key === 'sex').value,
          email: this.fields1.find(o => o.key === 'email').value,
          contactPhone: this.fields1.find(o => o.key === 'contactPhone').value,
          age: this.age,
          collegeId: this.fields2.find(o => o.key === 'collegeId').value,
          campusCardValidDate: this.fields2.find(
            o => o.key === 'campusCardValidDate',
          ).value,
          campusCardAvailable: this.campusCardAvailable.toString(),
          language: this.fields3.find(o => o.key === 'language').value,
          visaId: this.fields3.find(o => o.key === 'visaId').value,
          residenceCardId: this.fields3.find(o => o.key === 'residenceCardId')
            .value,
          residenceCardValidDate: this.fields3.find(
            o => o.key === 'residenceCardValidDate',
          ).value,
          residenceCardAvailable: this.residenceCardAvailable.toString(),
          bankBranch: this.fields4.find(o => o.key === 'bankBranch').value,
          bank: this.fields4.find(o => o.key === 'bank').value,
          cardNumber: this.fields4.find(o => o.key === 'cardNumber').value,
          accountHolder: this.fields4.find(o => o.key === 'accountHolder').value,
        }
        // this.$store.dispatch('parttimer/create', payload)
      },
      checkDate (evt) {
        const field = this.fields1.find(o => {
          if (
            o.key === 'dob' ||
            o.key === 'campusCardValidDate' ||
            o.key === 'residenceCardValidDate'
          ) {
            return o
          }
        })
        if (
          (evt.keyCode >= 48 && evt.keyCode <= 57) ||
          (evt.keyCode >= 96 && evt.keyCode <= 105) ||
          evt.keyCode === 231
        ) {
          evt = evt || window.event

          if (field.value) {
            var size = field.value.length
            if (size >= 10) field.value = field.value.slice(0, -1)
            if (
              (size === 4 && field.value > moment().year()) ||
              (size === 7 &&
                (Number(field.value.split('-')[1]) > 12 ||
                  Number(field.value.split('-')[1]) < 1)) ||
              (size === 10 &&
                (Number(field.value.split('-')[2]) > 31 ||
                  Number(field.value.split('-')[1]) < 1))
            ) {
              field.value = ''
              // add alert funct
              return
            }

            if (
              (size === 4 && field.value < moment().year()) ||
              (size === 7 && Number(field.value.split('-')[1]) < 13)
            ) {
              field.value += '-'
            }
          }
        }
      },
      getVisaDocs (item) {
        this.$store.dispatch('visa/getVisaById', { id: item })
      },
      reloadError () {
        this.checkCollegeId = ''
        this.fields1.forEach(field => {
          field.customerValidate = ''
        })
        this.fields3.forEach(field => {
          field.customerValidate = ''
        })
      },
      checkValidate () {
        this.reloadError()
        let error = false
        if (!this.fields1[0].value.trim()) {
          this.fields1[0].customerValidate = this.$t('validator.required')
          error = true
        }
        if (!this.fields1[1].value.trim()) {
          this.fields1[1].customerValidate = this.$t('validator.required')
          error = true
        }
        if (!this.fields1[2].value.trim()) {
          this.fields1[2].customerValidate = this.$t('validator.required')
          error = true
        } else if (
          !/^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/.test(
            this.fields1[2].value,
          )
        ) {
          error = true
        } else if (
          moment(this.fields1[2].value).format('YYYY-MM-DD') >=
          moment().format('YYYY-MM-DD')
        ) {
          error = true
        }
        if (
          this.fields1[5].value.trim() &&
          !/^[+]?[(]?[0-9]{4}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,5}$/.test(
            this.fields1[5].value,
          )
        ) {
          error = true
        }
        if (!this.fields2[0].value) {
          this.checkCollegeId = this.$t('validator.required')
          error = true
        }
        if (
          this.fields2[1].value.trim() &&
          !/^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/.test(
            this.fields2[1].value,
          )
        ) {
          error = true
        }
        if (!this.fields3[0].value.trim()) {
          this.fields3[0].customerValidate = this.$t('validator.required')
          error = true
        }
        if (!this.fields3[1].value) {
          this.fields3[1].customerValidate = this.$t('validator.required')
          error = true
        }
        if (!this.fields3[2].value.trim()) {
          this.fields3[2].customerValidate = this.$t('validator.required')
          error = true
        }
        if (!this.fields3[3].value.trim()) {
          this.fields3[3].customerValidate = this.$t('validator.required')
          error = true
        } else if (
          !/^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/.test(
            this.fields3[3].value,
          )
        ) {
          error = true
        }
        return error
      },
      setWorkDay () {
        let selectedDayShift = []
        this.selectedDay.map(day => {
          selectedDayShift.push({
            shift: this.selectedShift,
            day: day,
          })
        })
        selectedDayShift = selectedDayShift.reduce((unique, o) => {
          if (!unique.some(obj => obj.day === o.day && obj.shift === o.shift)) {
            unique.push(o)
          }
          return unique
        }, [])
        selectedDayShift.map(selected => {
          this.itemsDay.map(day => {
            if (selected.day === day.value) {
              this.selectedDayShift.push({
                ...selected,
                dayText: day.text,
              })
            }
          })
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
.title-bank {
  color: #4262ff;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 20px;
}
.chip {
  width: 412px;
  height: 127px;
  border-radius: 12px;
  margin-left: 50px;
  font-size: 18px;
  font-weight: 600;
  &:hover {
    cursor: pointer;
  }

  .v-icon {
    margin-left: 100px;
    background: rgba(197, 220, 250, 0.5);
    padding: 8% 14% 10% 14%;
    border-radius: 30px;
    color: gray;
  }
}
</style>
